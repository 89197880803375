// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Img from 'gatsby-image';

import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import Strip from '../Strip';
import Translate from '../Translate';

import styles from './style.module.css';

class ProductStrip extends Component {
	render() {
		const productStrip = this.props.productStrip;
		let imgTag = productStrip.image ? (
			<Img className={styles.productImg} fluid={productStrip.image} key={productStrip.image.src} />
		) : null;
		return (
			<Strip
				className={styles.productsStrip + ' ' + this.props.className}
				classNameChildren={styles.productsStripChildren + ' ' + this.props.classNameChildren}>
				<h2 className={styles.productsTitle}>{productStrip.title}</h2>
				{imgTag && !!productStrip.product_info_1 ? (
					<p className={styles.productInfo + ' ' + styles.productInfo1}>{productStrip.product_info_1}</p>
				) : (
					<span />
				)}
				{imgTag && !!productStrip.product_info_1 ? (
					<p className={styles.productInfo + ' ' + styles.productInfo2}>{productStrip.product_info_2}</p>
				) : (
					<span />
				)}
				{imgTag && !!productStrip.product_info_1 ? (
					<p className={styles.productInfo + ' ' + styles.productInfo3}>{productStrip.product_info_3}</p>
				) : (
					<span />
				)}
				{imgTag && !!productStrip.product_info_1 ? (
					<p className={styles.productInfo + ' ' + styles.productInfo4}>{productStrip.product_info_4}</p>
				) : (
					<span />
				)}
				{imgTag ? imgTag : <span />}
				{productStrip.product_related ? (
					<div
						className={styles.productsRelated}
						onClick={() => {
							this.props.onClickCallback();
						}}>
						<p className={styles.productsRelatedText}>
							<Translate>homePage.productsRelated</Translate>
						</p>
						<ul className={styles.productsRelatedList}>
							{productStrip.product_related.map((p) => {
								return (
									<li className={styles.productsRelatedItem} key={p.title}>
										{p.title}
									</li>
								);
							}, this)}
						</ul>
					</div>
				) : (
					<span />
				)}
			</Strip>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductStrip);
