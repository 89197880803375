import React from 'react';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import Img from 'gatsby-image';

import SiteComponent from '../../components/SiteComponent';
import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import styles from './style.module.css';

import Layout from '../../components/Layout';
import Strip from '../../components/Strip';
import Button from '../../components/Button';
import Translate from '../../components/Translate';
import ProductStrip from '../../components/ProductStrip';

class Products extends SiteComponent {
	constructor(props) {
		super(props, true);
	}

	goToServices() {
		let slugsServices = [
			{
				slug: 'servizi',
				polylang_current_lang_code: 'it',
			},
			{
				slug: 'services',
				polylang_current_lang_code: 'en',
			},
		];

		navigate(this.slugger(slugsServices));
	}

	slugger(slugs) {
		return `/${this.props.state.lang}/${slugs
			.map((item) => {
				let slug = '';
				if (item.polylang_current_lang_code === this.props.state.lang) {
					slug = item.slug;
				}
				return slug;
			})
			.join('')}`;
	}

	render() {
		const data = this.props.data;
		const fields = this.props.data.page.acf;

		/**
		 * Products strip
		 **/

		const productStrip = {
			title: fields.title,
			image: fields.cover_product.product_image.localFile.childImageSharp.fluid,
			image_thumb: fields.cover_product.product_image.localFile.childImageSharp.resolutions,
			product_info_1: fields.cover_product.product_info_1,
			product_info_2: fields.cover_product.product_info_2,
			product_info_3: fields.cover_product.product_info_3,
			product_info_4: fields.cover_product.product_info_4,
		};

		/**
		 * Generic strip
		 **/

		let strips = [];
		data.products.edges.forEach((nodeElement, index) => {
			const node = nodeElement.node;

			let textTile = (
				<section className={styles.textTile}>
					<h2 dangerouslySetInnerHTML={{ __html: node.title }} />
					<p dangerouslySetInnerHTML={{ __html: node.content }} />
				</section>
			);

			let imgTile = (
				<section className={styles.imgTile}>
					{node &&
					node.acf &&
					node.acf.image &&
					node.acf.image.localFile &&
					node.acf.image.localFile.childImageSharp &&
					node.acf.image.localFile.childImageSharp.fluid ? (
						<Img className={styles.productImage} fluid={node.acf.image.localFile.childImageSharp.fluid} />
					) : (
						<span>a</span>
					)}
				</section>
			);

			let strip =
				Math.abs(index % 2) === 1 ? (
					<Strip key={`${index}/odd`} className={styles.productStripOdd} classNameChildren={styles.productStrip}>
						{textTile}
						{imgTile}
					</Strip>
				) : (
					<Strip key={`${index}/even`} className={styles.productStriEven} classNameChildren={styles.productStrip}>
						{imgTile}
						{textTile}
					</Strip>
				);
			strips.push(strip);
		});

		return (
			<Layout metadata={this.props.data.page.yoast}>
				<ProductStrip productStrip={productStrip} />
				<Strip classNameChildren={styles.productStripChallenge}>
					<div>
						<h2 dangerouslySetInnerHTML={{ __html: this.props.data.page.acf.challenge_title }} />
						<p dangerouslySetInnerHTML={{ __html: this.props.data.page.acf.challenge_description }} />
					</div>
					<div>
						<h2 dangerouslySetInnerHTML={{ __html: this.props.data.page.acf.solution_title }} />
						<p dangerouslySetInnerHTML={{ __html: this.props.data.page.acf.solution_description }} />
					</div>
				</Strip>
				{strips}
				<Button
					onClickCallback={() => {
						this.goToServices();
					}}
					className={styles.productsButton}>
					<Translate>productsPage.goToServices</Translate>
				</Button>
			</Layout>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Products);

export const pageQuery = graphql`
	query($id: String!, $locale: String!) {
		page: wordpressPage(id: { eq: $id }) {
			id
			slug
			acf {
				title
				challenge_title
				challenge_description
				solution_title
				solution_description
				cover_product {
					product_info_1
					product_info_2
					product_info_3
					product_info_4
					product_image {
						source_url
						localFile {
							childImageSharp {
								original {
									src
								}
								resolutions(width: 500, height: 500) {
									...GatsbyImageSharpResolutions_withWebp_tracedSVG
								}
								fluid(maxWidth: 500) {
									sizes
									srcSet
									aspectRatio
									base64
									src
								}
							}
						}
					}
				}
			}
			language: polylang_current_lang_code
			polylang_translations {
				id
				slug
				title
				polylang_current_lang_code
			}
			yoast {
				title
				metadesc
				metakeywords
				noindex_nofollow: meta_robots_nofollow
				canonical
				social_title: opengraph_title
				social_description: opengraph_description
				social_image: opengraph_image
			}
		}
		products: allWordpressWpProducts(
			sort: { fields: [acf___display_order], order: ASC }
			filter: { polylang_current_lang_code: { eq: $locale }, acf: { hide: { eq: false } } }
		) {
			edges {
				node {
					id
					slug
					polylang_current_lang_code
					polylang_translations {
						id
						slug
						polylang_current_lang_code
					}
					title
					content
					acf {
						image {
							localFile {
								childImageSharp {
									fluid {
										sizes
										srcSet
										aspectRatio
										base64
										src
									}
								}
							}
						}
						display_order
						hide
					}
				}
			}
		}
	}
`;
